<template>
  <div class="Awarp">
    <dashCard
      class="carbonBox6"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox6_flowDirection','能源流向', getZEdata)}}</template>
      <template slot="aside"></template>
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox6
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "carbonBox6",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      searchObj: {},
      chartOption1: {},
      detailInfo: {
        chart1: {
          links: [],
        },
      },
      colorList: [
        "#cb2bd5",
        "#f03040",
        "#ff7300",
        "#ffd800",
        "#329484",
        "#686868",
        "#3366ff",
        "#1b0dd8",
        "#f2ac66",
        "#ff7300",
        "#f03040",
        "#ff7300",
        "#469E3F",
        "#329484",
        "#3366FF",
      ],
    };
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    //每月报警预警数
    getData() {
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/flow";
      var data = {
        deptCode: this.currentSelectDeptInfo.CODE,
      };
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          if (res.data.code == 0) {
            this.detailInfo = {};
            var chart1 = res.data.data;
            chart1.links.forEach((item) => {
              item.value = Number(item.value);
              if(item.model == '市电'){
                  item.lineStyle = {
                  color:"#c6c6c6"
                }
              }
              if(item.model == '光伏'){
                  item.lineStyle = {
                  color:"#42ff48"
                }
              }
              if(item.model == '空调'){
                  item.lineStyle = {
                  color:"#93d895"
                }
              }
              
            });
            if (chart1.links.length === 0) {
              chart1.nodes = [];
            }
            this.detailInfo.chart1 = chart1;
            this.initEchart();
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData0() {
      this.detailInfo = {};
      var chart1 = {
        nodes: [
          
        ],
        links: [
          
        ],
      };

      this.detailInfo.chart1 = chart1;
      this.initEchart();
    },
    initEchart() {
      var { nodes, links } = this.detailInfo.chart1;
      var color = this.colorList;
      // links.forEach((item, idx) => {
      //   var lineStyle = {
      //     normal: {
      //       color: color[idx],
      //       opacity: 0.1,
      //     },
      //   };
      //   links[idx].lineStyle = lineStyle;
      // });

      nodes.forEach((item, idx) => {
        if (item.type == 0) {
          var label = { normal: { position: "right" } };
          nodes[idx].label = label;
        } else {
          var label = { normal: { position: "left" } };
          nodes[idx].label = label;
        }
      });
      //console.log(links)
      var option = {
        animation: true,
        layoutAnimation: false,
        color: color,
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            nodeGap: 20,
            nodeWidth: 5,
            type: "sankey",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            left: "1%",
            right: "5%",
            data: nodes,
            links: links,
            lineStyle: {
              color: "source",
              curveness: 0.6,
            },
          },
        ],
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox6 {
  .card-content1 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
